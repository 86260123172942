$white: #FFFFFF;
$black: #000000;
$black-50: #00000080; // 50% opacity - 80

// new colors
$haiti: #0B002C;
$jacarta: #3C3356;
$turquoise: #6ED0DD;
$meeseeksBlue: #5AE1FF;
$geraldine: #DD6E6E;
$indigo: #281170;
$honeysuckle: #E8FF5A;
$hotPink: #FF5AB3;
$heliotrope: #D45AFF;
$bittersweet: #FF5A5A;
$bittersweet-50: #FF5A5A80; // 50% opacity - 80
$mediumAquamarine: #5AFF9C;

// old colors - delete when no longer used
$ceSoir: #A479AF;
$fedora: #694E70;
$prelude: #C5A9CC;
$lemon: #FEDF1D;
$lucky: #A28E12;
$cornField: #FEF4B5;
$veryLightGrey: #CACACA;
$red: #E81313;
$darkRed: #950C0C;
$bittersweet: #F16868;