@font-face {
    font-family: 'McLaren';
    src: url(../assets/fonts/mclaren/McLaren-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Komika-Hand-Bold';
    src: url(../assets/fonts/Komika-Hand/Komika_Hand_Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Komika-Hand-Italic';
    src: url(../assets/fonts/Komika-Hand/Komika_Hand_Italic.ttf) format('truetype');
}

@font-face {
    font-family: 'icomoon';
    src: url(../assets/fonts/icomoon/icomoon.eot);
    src: url(../assets/fonts/icomoon/icomoon.eot#iefix) format('embedded-opentype'),
        url(../assets/fonts/icomoon/icomoon.ttf) format('truetype'),
        url(../assets/fonts/icomoon/icomoon.woff) format('woff'),
        url(../assets/fonts/icomoon/icomoon.svg#icomoon) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}