@import './typography';
@import './fonts';
@import './colors';
@import './mixins';
@import './icons';

html,
body,
#root {
    margin: 0;
    height: 100%;
    background-color: $haiti;
}

.app {
    height: inherit;
    width: 100%;
    display: flex;
    flex-direction: column;

    &__content {
        height: inherit;
        width: inherit;
    }
}

.error-text {
	font-size: 24px;
	color: $bittersweet;
	margin: 20px;
}

.back-button {
	height: 50px;
	width: 50px;
	font-size: 50px;
	color: $white;
	position: absolute;
	left: 30px;
	top: 30px;

	&:hover {
		color: $turquoise;
		cursor: pointer;
	}
}