[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-bookmark:before {
    content: "\e900";
}

.icon-back:before {
	content: "\e906";
  }

.icon-crown:before {
    content: "\e901";
}

.icon-copy:before {
    content: "\e902";
}

.icon-hut:before {
    content: "\e903";
}

.icon-logout:before {
    content: "\e904";
}

.icon-eye:before {
    content: "\e905";
}

.icon-divider:before {
	content: "\e907";
}
.icon-snowflake:before {
	content: "\e908";
}
.icon-settings:before {
	content: "\e909";
}